import {
  getChildren,
  getVariants,
  isConfigurable
} from '@futureecom/futureecom-js/dist/helpers/catalog/product-helpers'
import type {
  Product,
  ProductConfigurable,
  ProductProperty
} from '@futureecom/futureecom-js/dist/services/catalog-service'

export const getUpdateProductPayload = (val: Partial<Product>): Partial<Product> => {
  if (isConfigurable(val)) {
    const { variants, children, children_ids, ...rest } = val
    return rest
  }
  return val
}

export const findMatchingVariantChildren = (
  product: ProductConfigurable,
  keyedProperties: Record<string, ProductProperty>
): Product | undefined => {
  const selectedProperties = Object.entries(keyedProperties).map(([_, value]) => ({ ...value }))

  return getChildren(product).find((item) => {
    return item.properties?.every(({ name, value }) => {
      return selectedProperties.find((item) => item.name === name && item.value === value)
    })
  })
}

export const getSingleProperties = (val: ProductConfigurable): Record<string, ProductProperty> => {
  return getVariants(val).reduce((prev, variant) => {
    const { options, key, name } = variant
    return options.length === 1 && key ? { ...prev, [key]: { value: options[0], name, key } } : prev
  }, {})
}
